<template>
  <b-container class="quickScore" :class="{'adt' : develop == 'adt' }">
    <div>
      <div v-if="home == false">
        <b-row>
          <b-col
            v-if="tmparamsMini.multi_course === 'Y'"
            class="col-md-6 multi"
          >
            <span
              class="multiList"
              v-for="(multi, index) in tmparamsMini.courses.courses_entry"
              :key="index"
            >
              <span :class="multi.course_X_colour" class="ballpin">•</span
              ><span class="multiName">{{ multi.course_X_id }}</span>
            </span>
          </b-col>
        </b-row>
      </div>
      <template v-if="multi_tourns == 'Y' && home === true">
        <b-row class="pastbtnRow">
          <!-- <b-col class="tabBtn" v-for="(past, index) in past_tourns" :key="index">
            <b-button
              class="pastbtn"
              @click="swapTour(past.code)"
              :class="{
                activeBtn: course === past.code
              }"
              >{{ past.name }}</b-button
            >
          </b-col> -->
          <b-col class="tabBtn padRight" col="12" lg="6">
            <b-button
              class="pastbtn"
              @click="swapTour(tm.past_tourns.past_tourns_entry.code)"
              :class="{
                activeBtn: course === tm.past_tourns.past_tourns_entry.code
              }"
              >{{ tm.past_tourns.past_tourns_entry.name }}</b-button
            >
          </b-col>
          <b-col class="tabBtn padLeft" col="12" lg="6">
            <b-button
              class="pastbtn"
              @click="swapTour(tm.code)"
              :class="{ activeBtn: course === tm.code }"
              >{{ tm.short_name }}</b-button
            >
          </b-col>
          <!-- <b-col class="tabBtn" v-if="tm.future_tourns !== ''">
            <b-link
              :to="
                'tournaments-information/' +
                  tm.future_tourns.future_tourns_entry.code +
                  '/' +
                  this.season
              "
            >
              <b-button
                class="pastbtn"
                @click="swapTour(tm.future_tourns.future_tourns_entry.code)"
                :class="{
                  activeBtn:
                    course === tm.future_tourns.future_tourns_entry.code
                }"
                >{{ tm.future_tourns.future_tourns_entry.name }}</b-button
              >
            </b-link>
          </b-col> -->
        </b-row>
      </template>
      <ReportTable
        :data="data"
        :title="title"
        :home="home"
        :updown="updown"
        :config="config"
        :team="teamEvent"
        :match="match"
        :Stableford="Stableford"
        :code="courseCode"
        :Year="Year"
        :season="Year"
        :develop="develop"
      />

      <template v-if="title.includes('Entries')">
        <b-row>
          <b-col class="padRight" :class="{noPad : reports.report_title.length}">
            <div class="FullListingBtn" :class="{pulse: live_scoring == 'Y' && title.includes('Score'), liveScoring : live_scoring == 'Y' && title.includes('Score')}">
            <!-- <div class="FullListingBtn"> -->
              <b-button
                :to="{
                  name: 'adt-reports',
                  query: {
                    url: currentReport,
                    id: Year,
                    code: courseCode,
                    title: title
                  }
                }"
              >
                Entries
              </b-button>
            </div>
          </b-col>
          <b-col class="padLeft" v-if="Array.isArray(reports)">
            <div class="FullListingBtn">
              <b-button
                :to="{
                  name: 'adt-reports',
                  query: {
                    url: 'http://adt.ocs-asia.com/tic/tmdraw.cgi?tourn=' + courseCode+ '~season=' + Year + '~round=' + '1' + '~',
                    id: Year,
                    code: courseCode,
                    title: 'Round ' + '1' + ' Draw'
                  }
                }"
              >
                <template>
                  Draw
                </template>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </template>
      <template v-else-if="title.includes('Score')">
        <b-row>
          <b-col class="padRight">
            <div class="FullListingBtn" :class="{pulse: live_scoring == 'Y' && title.includes('Score'), liveScoring : live_scoring == 'Y' && title.includes('Score')}">
              <b-button
                :to="{
                  name: 'adt-reports',
                  query: {
                    url: currentReport,
                    id: Year,
                    code: courseCode,
                    title: title
                  }
                }"
              >
                Live Score
              </b-button>
            </div>
          </b-col>
          <b-col class="padLeft">
            <div class="FullListingBtn">
              <b-button
                :to="{
                  name: 'adt-reports',
                  query: {
                    url: 'http://asian.ocs-asia.com/tic/tmdraw.cgi?tourn=' + courseCode+ '~season=' + Year + '~round=' + nextRound + '~',
                    id: Year,
                    code: courseCode,
                    title: 'Round ' + nextRound + ' Draw'
                  }
                }"
              >
                <template>
                  Draw
                </template>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </template>
      <template v-else>
        <div class="FullListingBtn">
          <b-button
            :to="{
              name: 'adt-reports',
              query: {
                url: currentReport,
                id: Year,
                code: courseCode,
                title: title
              }
            }"
          >
            <template v-if="title.includes('Draw')">
              See Full Draw Listing
            </template>
            <template v-else-if="title.includes('Result')">
              <span>Final Result</span>
            </template>
          </b-button>
        </div>
      </template>

    </div>
  </b-container>
</template>

<script>
// import apiCall from "@/services/api.js";
import ReportTable from "@/components/reportTable.vue";
import axios from 'axios';
export default {
  name: "homeTourOne",
  components: {
    ReportTable,
  },
  props: [
    "data",
    "Newreport",
    "Year",
    "courseCode",
    "currentReport", 
    "title",
    "reportURL",
    "config",
    "multi_tourns",
    "pastT",
    "head",
    "tm",
    "past_tourns",
    "tm_params",
    "course",
    "live_scoring",
    "match",
    "Stableford",
    "develop",
    "reports"
  ],

  data() {
    return {
      updown: false,
      fields: [
        { isActive: true, key: "serial_no", label: "No." },
        { isActive: true, key: "playing_name", label: "Player Name" },
        { isActive: true, key: "", label: "Nat." },
        { isActive: true, key: "status_text", label: "Entry Status" },
        { isActive: true, key: "player_profile", label: "" },
      ],
      tableRow: "tableRow",
      tab: "tab",
      cell: "cell",
      home: true,
      second_tourn_tixc: {},
      winnerType: {},
    };
  },
  computed: {
    liveOn: function(message) {
      if (this.data.live_scoring === "Y") message = " - Livescoring is on";
      else message = " - " + this.data.round_head;
      return message;
    },
    teamEvent: function(team) {
      var array = this.data;
      if (array["team_scores"] !== undefined) team = "Y";
      else team = "N";
      return team;
    },
  },
  methods: {
    swapTour(tournCode) {
      this.course = tournCode;
      // this.updateStuff();
      this.getSecondTournTicx(tournCode);
    },
    getSecondTournTicx(code) {
      // console.log("IN")
      axios
        .get(this.Newreport)
        .then(response => {
          this.data = response.data;
          return axios.get(
            process.env.VUE_APP_APIFULL_URL +
              this.tm_params.tm_params.season_code +
              "/" +
              this.tm_params.tm_params.season_code +
              "-" +
              code +
              "-tmticx.json?randomadd=" +
              new Date().getTime()
          );
        })
        .then(response => {
          this.second_tourn_tixc = response.data;
          // console.log("this.second_tourn_tixc")
          // console.log(this.second_tourn_tixc)
          this.winnerType = response.data.winner_result;
          if (typeof this.second_tourn_tixc.reports != "undefined") {
            this.second_tourn_tixc.reports.reports_entry.forEach(element => {
              if (element.report_url.indexOf("tmscore") !== -1) {
                this.report = element.report_url;
              }
              if (element.report_url.indexOf("tmresult") !== -1) {
                this.report = element.report_url;
                this.updateStuff();
              }
            });
          }
        });
    },
    upDown() {
      this.updown = !this.updown;
    },
  },
  created() {
    setInterval(this.updateStuff, 120000);
  },

};
</script>

<style scoped>
.noPad {
  padding-right: 15px!important;
}
.FullListingBtn.borderRight {
  border-right: 1px solid #fff;
}
.padRight {
  padding-right: 0;
}
.padLeft {
  padding-left: 0;
}
.liveScoring > a.btn {
  background-color: #aa0003d9!important;
}
/* a.btn {
  background-color: #AA0003!important;
} */
.pulse {
  animation: pulse-red 2s infinite;
  border-left: 0px solid !important;
}
.pulse:hover {
  background-color: #ff5c39 !important;
}
@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(170, 0, 3, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}
.pulsate {
  animation: pulsate 2s ease-out;
  animation-iteration-count: infinite;
  opacity: 1;
}
@keyframes pulsate {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
::v-deep td.posnum {
  padding: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  display: block;
  max-width: 58px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2px;
  margin-bottom: 0px;
  height: 45px;
  position: relative;
  top: 5px;
  color: #7E7E7E;
}
.FullListingBtn > a.btn {
  background-color: #76c3bd;
  border-radius: 0;
  color: #fff!important;
  width: 100%;
  height: 59px;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 3.1;
  border-color: transparent;
}
.FullListingBtn > a.btn:hover {
  background-color: #1c857c;
  border-radius: 0;
  color: #fff!important;
  width: 100%;
  height: 59px;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 3.1;
  border-color: transparent;
}
::v-deep li.nav-item.Flisting-btn.right {
  display: block;
  padding: 0;
}
.quickScore {
  padding: 0 0 50px 0;
}
li {
  list-style: none;
}
.links {
  text-align: center;
  padding: 10px;
}
.FullList {
  background-color: #0A3F7F;
}
.blue {
  background-color: #1a2337;
}
.links > li > a {
  color: #fff !important;
}
::v-deep .nav-item {
  font-size: 14px;
  font-weight: 500;
}
::v-deep #reportTable {
  padding-bottom: 0!important;
  margin-bottom: 0px;
  overflow: hidden!important;
  /* height: 525px; */
  height: 547px;
}
::v-deep .row.pastbtnRow {
  /* top: 1px;
  right: 21.8%;
  position: absolute;
  width: 300px; */
  /* margin-left: 0px !important;
  margin-right: 0px !important; */
  margin-top: 5px !important;
}
::v-deep button.pastbtn {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  background: #5897E6;
  color: #fff;
  border-radius: 0;
  border: 0px solid transparent;
  font-size: 11px;
  height: 45px;
}
::v-deep button.btn.pastbtn.btn-secondary.activeBtn {
  /* background: #005699; */
  background: #042F62;
  color: #fff;
  border-radius: 0;
  border: 0px solid transparent;
}
::v-deep button.btn.pastbtn.btn-secondary.activeBtn:hover {
  background: #042F62;
  border-radius: 0;
  border: 0px solid transparent;
}
::v-deep .padRight {
  padding-right: 0;
}
::v-deep .padLeft {
  padding-left: 0;
}

::v-deep table.scores-only-table.reportsHome > tbody> tr > td:nth-child(5)  > span > span {
  display: none;
}
::v-deep .title.main.TournamentTitle {
  background-color: #1c857c !important;
}
::v-deep .title {
  background-color: #1c857c !important;
}
::v-deep span.Cap {
  text-transform: capitalize;
}
::v-deep .reportWidget_title {
  font-size: 10px;
  color: #76c3bd!important;
  text-transform: uppercase;
}
::v-deep .gradHome {
  background-image: linear-gradient(to right, #76c3bd , #1c857c);
}
::v-deep td.posnum.homeposnum > span{
  color: #1c857c!important;
}
::v-deep a.removeUnderLineHome > span.Cap {
  color: #7E7E7E !important;
  text-decoration: none !important;
  font-weight: 500 !important;
}
::v-deep .initialHome {
  font-weight: 500 !important;
  color: #7E7E7E;
}

::v-deep a.removeUnderLineHome > span{
  text-decoration: none;
  color: #7E7E7E !important;
}

::v-deep a.removeUnderLineHome:hover > span {
  text-decoration: none;
  color: #1c857c !important;
}
</style>